import { useEffect, useState } from 'react'
import { Listbox } from '@headlessui/react'
import { ChevronDownIcon } from "@heroicons/react/24/solid"

const statusList = [
  { id: 0, name: 'Not completed', value: 'active' },
  { id: 1, name: 'Completed', value: 'booked' },
  { id: 2, name: 'Cancelled', value: 'Rejected' },
]

const ChangeStatusListbox = ({
  status,
  bookingId,
  token,
  afterSubmit, //fetchBookingData
  openDropDown
}) => {
  const [selectedStatus, setSelectedStatus] = useState(statusList[0]);

  const handleSubmit = async () => {
    const url = "https://evmainbackend.theevshowroom.com/api/updatebookingstatus";
    try{
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          token: `${token}`
        },
        body: JSON.stringify({
          bookingId,
          bookingStatus: selectedStatus.id
        })
      });
      const dataResponse = await response.json();
      if(dataResponse.status){
        afterSubmit(); //fetchBookingData
      }else{
        // setResponseErrors(dataResponse.message);
      }
      openDropDown(false);
    } catch(e){
      console.log('Error while changing status: ',e);
    }
  }
  
  useEffect(()=>{
    setSelectedStatus(statusList.find(st => st.value === status) || statusList[0]);
  },[status])

  return (
    <div className='flex flex-col items-end'>
      <Listbox value={selectedStatus} onChange={setSelectedStatus}>
        <Listbox.Button className="flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1">
          <div className='flex justify-between items-center w-full'>
            <p className='text-left'>{selectedStatus.name}</p>
            <ChevronDownIcon className='w-5 h-5' />
          </div>
          </Listbox.Button>
        <Listbox.Options className="relative z-50 max-h-96 min-w-[8rem] w-full overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md">
          {statusList.map((status) => (
            <Listbox.Option
              key={status.id}
              value={status}
              className="relative border-b flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-4 pr-2 text-sm outline-none hover:bg-gray-200"
            >
              {status.name}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
      <button className='border rounded-md p-1 px-4 mt-4 bg-black text-white' onClick={() => handleSubmit()}>
        Apply
      </button>
    </div>
  )
}

export default ChangeStatusListbox
