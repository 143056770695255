import React from 'react'
import { UserInteractionPatternsTable } from '../../../components/dashboard/Table'

const ClickDashboardModels = () => {
  return (
    <div className='sm:px-8'>
      <UserInteractionPatternsTable/>
    </div>
  )
}

export default ClickDashboardModels
