/* eslint-disable */

import Links from "./components/Links";
import routes from "../../routes";
import audi from "../../assets/img/audi-logo-2.png"
import { Box, Drawer } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';

const Sidebar = ({ open, onClose }) => {
  const drawerWidth = 240;

  return (
    <div>
      <Drawer
        open={open}
        onClose={onClose}
        variant="temporary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <div>
          <Toolbar>
            <div className={`mx-auto flex items-center`}>
              <img className="h-20 w-24" src={audi} alt="logo"/>
            </div>
          </Toolbar>
          <Divider />
          <Links routes={routes} />
        </div>
        
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', lg: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open={open}
      >
        <Box component="div" sx={{ p: 2 }}>
          <Toolbar >
            <div className={`mx-auto flex items-center`}>
              <img className="h-20 w-24" src={audi} alt="logo"/>
            </div>
          </Toolbar>
          <Links routes={routes} />
        </Box>
      </Drawer>
    </div>
  );
};

export default Sidebar;
