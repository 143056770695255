import React from 'react'
import { ClicksOverTimeTable } from '../../../components/dashboard/Table'

const ClickDashboardTime = () => {
  return (
    <div className='sm:px-8'>
      <ClicksOverTimeTable/>
    </div>
  )
}

export default ClickDashboardTime
