import { useState } from "react";
import logoCar from "../../assets/img/ev-logo-neww.png"
import { Formik } from 'formik';
import { Link, useNavigate } from "react-router-dom";
import {
  EyeIcon,
  EyeSlashIcon
} from "@heroicons/react/24/outline";

const Register = () => {
  const [responseErrors, setResponseErrors] = useState(false);
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);

  const handleSubmit = async (values, {setSubmitting}) => {
    const url = "https://evmainbackend.theevshowroom.com/api/vendor-registration";
    try{
      const {
        vendorName,
        vendorEmail,
        vendorPassword,
        vendorUrl,
        vendorPhone1,
        vendorPhone2,
        vendorAddress1,
        vendorAddress2,
        vendorCity,
        vendorState,
        vendorCountry,
        vendorZipcode,
        vendorCatName,
        vendorCatLogo,
        vendorCatImage
      } = values;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
          Cookie: 'connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E'
        },
        body: JSON.stringify({
          vendorName,
          vendorEmail,
          vendorPassword,
          vendorUrl,
          vendorPhone1,
          vendorPhone2,
          vendorAddress1,
          vendorAddress2,
          vendorCity,
          vendorState,
          vendorCountry,
          vendorZipcode,
          vendorCatDetail: {
            vendorCatName,
            vendorCatLogo,
            vendorCatImage
          }
        })
      });
      const dataResponse = await response.json();
      if(dataResponse.status){
        setResponseErrors(null);
        navigate('/login');
      }else{
        setResponseErrors(dataResponse.message);
      }
      setSubmitting(false);
    } catch(e){
      console.log('Error while login: ',e);
    }
  }

  const validateForm = (values) => {
    setResponseErrors(null);
    const errors = {};
    if(!values.vendorName){
      errors.vendorName = 'Name is required'
    }

    if(!values.vendorUrl){
      errors.vendorUrl = 'Website Url is required'
    }

    if(!values.vendorPhone1){
      errors.vendorPhone1 = 'Phone Number is required'
    }

    if(!values.vendorAddress1){
      errors.vendorAddress1 = 'Address Line 1 is required'
    }

    if(!values.vendorCity){
      errors.vendorCity = 'City is required'
    }

    if(!values.vendorCountry){
      errors.vendorCountry = 'Country is required'
    }

    if(!values.vendorZipcode){
      errors.vendorZipcode = 'Zipcode is required'
    }

    if(!values.vendorCatName){
      errors.vendorCatName = 'Category Name is required'
    }

    if(!values.vendorCatLogo){
      errors.vendorCatLogo = 'Logo Url is required'
    }

    if(!values.vendorCatImage){
      errors.vendorCatImage = 'Image Url is required'
    }

    if (!values.vendorEmail) {
      errors.vendorEmail = 'Email is required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.vendorEmail)
    ) {
      errors.vendorEmail = 'Invalid email address';
    }

    if(!values.vendorPassword){
      errors.vendorPassword = 'Password is required'
    } else if( values.vendorPassword.length < 8){
      errors.vendorPassword = 'Password should be of minimum 8 characters length'
    }
    return errors;
  }
  return(
    <section className="">
      <div className="items-center justify-center bg-white">
        <div className="w-full px-[20%]">
          <div className="flex flex-col items-center mb-10 p-4">
            <img
              src={logoCar}
              alt="Logo"
              className="w-24 h-16 mb-2"
            />
            <p className="text-2xl content-center font-semibold	text-stone-800">Vendor Registration</p>
          </div>

          <Formik
            initialValues={{
              vendorName: '',
              vendorEmail: '',
              vendorPassword: '',
              vendorUrl: '',
              vendorPhone1: '',
              vendorPhone2: '',
              vendorAddress1: '',
              vendorAddress2: '',
              vendorCity: '',
              vendorState: '',
              vendorCountry: '',
              vendorZipcode: '',
              vendorCatName: '',
              vendorCatLogo: '',
              vendorCatImage: ''
            }}
            validate={validateForm}
            onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
          >
            {({ 
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting, 
            }) => (
              <form
                onSubmit={handleSubmit}
              >
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                  <div className="mb-10 col-span-2">
                    <input
                      type="name"
                      name="vendorName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorName}
                      autoComplete="name"
                      placeholder="Vendor Name"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorName && errors.vendorName}</p>
                  </div>
                  <div className="mb-10 col-span-1">
                    <input
                      type="email"
                      name="vendorEmail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorEmail}
                      autoComplete="email"
                      placeholder="Email"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorEmail && errors.vendorEmail}</p>
                  </div>
                  <div className="mb-10 col-span-1">
                    <div className="flex items-center relative">
                      <input
                        type={showPass ? 'text' : 'password'}
                        name="vendorPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.vendorPassword}
                        placeholder="Password"
                        className="block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]"
                      />
                      <button type="button" className="absolute right-0 ring-0" onClick={() => setShowPass(prev => !prev)}>
                        {showPass ? <EyeSlashIcon className="ring-0 w-6 h-6 mx-2"/> :<EyeIcon className="ring-0 w-6 h-6 mx-2" />}
                      </button>
                    </div>
                    <p className="text-orange-600 ml-2">{touched.vendorPassword && errors.vendorPassword}</p>
                  </div>
                  <div className="mb-10 col-span-2">
                    <input
                      type="text"
                      name="vendorUrl"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorUrl}
                      placeholder="Website Url"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorUrl && errors.vendorUrl}</p>
                  </div>
                  <div className="mb-10 col-span-1">
                    <input
                      type="text"
                      name="vendorPhone1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorPhone1}
                      placeholder="Phone Number 1"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorPhone1 && errors.vendorPhone1}</p>
                  </div>
                  <div className="mb-10 col-span-1">
                    <input
                      type="text"
                      name="vendorPhone2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorPhone2}
                      placeholder="Phone Number 2"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorPhone2 && errors.vendorPhone2}</p>
                  </div>
                  <div className="mb-10 col-span-1">
                    <input
                      type="text"
                      name="vendorAddress1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorAddress1}
                      placeholder="Address Line 1"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorAddress1 && errors.vendorAddress1}</p>
                  </div>
                  <div className="mb-10 col-span-1">
                    <input
                      type="text"
                      name="vendorAddress2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorAddress2}
                      placeholder="Address Line 2"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorAddress2 && errors.vendorAddress2}</p>
                  </div>
                  <div className="mb-10 col-span-1">
                    <input
                      type="text"
                      name="vendorCity"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorCity}
                      placeholder="City"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorCity && errors.vendorCity}</p>
                  </div>
                  <div className="mb-10 col-span-1">
                    <input
                      type="text"
                      name="vendorState"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorState}
                      placeholder="State"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorState && errors.vendorState}</p>
                  </div>
                  <div className="mb-10 col-span-1">
                    <input
                      type="text"
                      name="vendorCountry"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorCountry}
                      placeholder="Country"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorCountry && errors.vendorCountry}</p>
                  </div>
                  <div className="mb-10 col-span-1">
                    <input
                      type="text"
                      name="vendorZipcode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorZipcode}
                      placeholder="Zip code"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorZipcode && errors.vendorZipcode}</p>
                  </div>
                  <div className="col-span-2">
                    <p className="text-lg">Enter Categories Details</p>
                  </div>
                  <div className="mb-10 col-span-2">
                    <input
                      type="text"
                      name="vendorCatName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorCatName}
                      placeholder="Category Name"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorCatName && errors.vendorCatName}</p>
                  </div>
                  <div className="mb-10 col-span-2">
                    <input
                      type="text"
                      name="vendorCatLogo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorCatLogo}
                      placeholder="Category Logo Url"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorCatLogo && errors.vendorCatLogo}</p>
                  </div>
                  <div className="mb-10 col-span-2">
                    <input
                      type="text"
                      name="vendorCatImage"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vendorCatImage}
                      placeholder="Category Image Url"
                      className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                    />
                    <p className="text-orange-600 ml-2">{touched.vendorCatImage && errors.vendorCatImage}</p>
                  </div>

                </div>
                <p className="text-orange-600 text-center mt-2">{responseErrors}</p>

                <div className="mb-12 pb-1 pt-1 text-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="block w-full rounded-md bg-black px-3.5 py-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-black-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Register
                  </button>

                  <div className="mb-12 pb-1 pt-1 text-center">
                    <p className="mb-0 mr-2 mt-10">Already Registered?</p>
                    <Link to="/login">
                      <span className="text-black underline">
                        Login
                      </span>
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </Formik>

        </div>
      </div>
    </section>
  )
}

export default Register;