import React from 'react'
import { Table1 } from '../../../components/dashboard/Table'

const Payment = () => {
  return (
    <div className='sm:px-8'>
      <Table1/>
    </div>
  )
}

export default Payment