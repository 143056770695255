/* eslint-disable */
import React from "react";
import { useLocation } from "react-router-dom";
import DashIcon from "../../../components/icons/DashIcon";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box } from "@mui/material";

export function SidebarLinks(props) {
 
  let location = useLocation();

  const { routes } = props;

  const activeRoute = (routeName) => {
    const pathname = location.pathname;
    return pathname.endsWith('/'+routeName);
  };

  return (
    <Box component="div" >
      <List >
        {routes.map((route) => {
          if (
            route.layout === "/admin" ||
            route.layout === "/auth" ||
            route.layout === "/rtl"
          ){
            return (
              <ListItem
                key={route.name}
              >
                <ListItemButton 
                  component="a" 
                  href={route.layout + "/" + route.path} 
                  selected={activeRoute(route.path)}
                  sx={{
                    p: 2,
                    borderRadius: '10px'
                  }}
                >
                  <ListItemIcon>
                    {route.icon ? route.icon : <DashIcon />}
                  </ListItemIcon>
                  <ListItemText primary={route.name} />
                </ListItemButton>
              </ListItem>
            )
          }
        })}
      </List>
    </Box>
  )
}

export default SidebarLinks;
