import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Login from "./layouts/account/Login";
import Admin from "./layouts/admin";
import Register from "./layouts/account/Register";
import ForgotPassword from "./layouts/account/ForgotPassword";
import ResetPassword from "./layouts/account/ResetPassword";
import { AuthContext } from "./context/AuthContext";
import { createTheme } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';


const App = () => {
  const [user, setUser] = useState(null);
  const theme = createTheme({
    components:{
      MuiListItemButton:{
        styleOverrides:{
          root: ({ ownerState }) => ({
            ...(ownerState.selected && {
              backgroundColor: 'black',
              color: 'white'
            })
          }),
          '& .MuiListItemIcon-root':{
            color: 'white'
          },
        }
      },
      MuiButton:{
        variants: [
          {
            props: { variant: 'text' },
            style: {
              borderWidth: `0px`,
            },
          },
        ],
      },
      MuiDrawer:{
        styleOverrides:{
          paper: {
            borderRightWidth: '0px'
          }
        }
      }
    },
    palette: {
      secondary: {
        main: '#000000'
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{ user, setUser }}>
        <Routes>
          <Route path="admin/*" element={<Admin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
      </AuthContext.Provider>
    </ThemeProvider>
  );
};

export default App;
