import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const generateFullMonthData = (month, year, data) => {
  const daysInMonth = new Date(year, month, 0).getDate();
  const fullMonthData = [];

  for (let day = 2; day <= daysInMonth +1; day++) {
    const date = new Date(year, month - 1, day).toISOString().split('T')[0];
    const clickData = data.find((item) => item.click_date.startsWith(date)) || { click_date: date, click_count: 0 };
    fullMonthData.push(clickData);
  }

  return fullMonthData;
};

const DailyClicksChart = ({ data }) => {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const uniqueMonths = [...new Set(data.map(item => item.click_date.substring(0, 7)))];
    if (uniqueMonths.length > 0) {
      setSelectedMonth(uniqueMonths[uniqueMonths.length - 1]); // Default to the last available month
    }
  }, [data]);

  useEffect(() => {
    if (selectedMonth) {
      const [year, month] = selectedMonth.split('-');
      const filtered = generateFullMonthData(parseInt(month), parseInt(year), data);
      setFilteredData(filtered);
    }
  }, [selectedMonth, data]);

  useEffect(() => {
    if (filteredData.length > 0) {
      const labels = filteredData.map(item => new Date(item.click_date).getDate());
      const clickCounts = filteredData.map(item => item.click_count);

      setChartData({
        labels,
        datasets: [
          {
            label: 'No. of Clicks',
            data: clickCounts,
            fill: false,
            borderColor: 'black', // Change line color to black
            tension: 0.1,
          },
        ],
      });
    }
  }, [filteredData]);

  const handleChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          Daily User Clicks
        </Typography>
        <FormControl variant="outlined" size="small" style={{ marginBottom: '16px' }}>
          <InputLabel id="month-selector-label">Month</InputLabel>
          <Select
            labelId="month-selector-label"
            value={selectedMonth}
            onChange={handleChange}
            label="Month"
          >
            {[...new Set(data.map(item => item.click_date.substring(0, 7)))].map(month => (
              <MenuItem key={month} value={month}>
                {new Date(`${month}-01`).toLocaleString('default', { month: 'long', year: 'numeric' })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {chartData.labels && chartData.labels.length > 0 ? (
          <Line 
            data={chartData} 
            options={{
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Date',
                  },
                  ticks: {
                    callback: function(value, index, values) {
                      return value + 1;
                    }
                  }
                },
                y: {
                  title: {
                    display: true,
                    text: 'Number of Clicks',
                  },
                  beginAtZero: true,
                },
              },
            }} 
          />
        ) : (
          <Typography variant="body1" component="div">
            No data available for the selected month.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default DailyClicksChart;
