import { useContext, useRef, useState } from "react";
import { AuthContext } from '../../context/AuthContext'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import navbarimg from "../../assets/img/Navbar.webp"
import { Avatar, Box, Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Stack } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const { user } = useContext(AuthContext);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleLogout = () => {
    logout();
    navigate('/login');
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };



  return (
    <Box component="nav" padding={{xs: "6px", sm: "10px"}} className="sticky top-0 z-40 backdrop-blur-xl">
      <Stack sx={{width: "100%"}} justifyContent={{xs:"space-between", sm: "flex-end"}} direction="row">
        {/* className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-end gap-2 rounded-full dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2" */}
        
        <ButtonGroup
          variant="text"
          ref={anchorRef}
          aria-label="Button group with a nested menu"
        >

          <Button sx={{display: { xs: 'block', sm: 'none' }}} onClick={onOpenSidenav}>
            <MenuIcon color="action"/>
          </Button>

          <Button variant="text" disableRipple disableElevation sx={{borderRightWidth: '0px', ":hover": {backgroundColor: 'white'}}} onClick={handleToggle}>
            <Avatar
              alt="User Image"
              src={navbarimg}
              sx={{ width: 40, height: 40 }}
            />
          </Button>

          <Popper
            sx={{
              zIndex: 1,
              borderRightWidth: '0px'
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-end"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>

                    <MenuItem disableRipple>
                      <Box component='a'
                        href=""
                        // className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                      >
                        Profile Settings
                      </Box>
                    </MenuItem>

                    <MenuItem onClick={handleLogout} disableRipple>
                        Log Out
                    </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ButtonGroup>

        {/* <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={navbarimg}
              alt="Elon Musk"
            />
          } 
          children={
            (fn) => {
              return (
                <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white text-black bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:bg-gray-900 dark:text-white dark:shadow-none">
                  <div className="p-4">
                    <div className="flex items-center gap-2">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        👋 Hey, {user?.vendorName}
                      </p>{" "}
                    </div>
                  </div>
                  <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                  <div className="flex flex-col p-4">
                    <a
                      href=""
                      className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                    >
                      Profile Settings
                    </a>
              
                    <button
                      onClick={handleLogout}
                      className="mt-3 text-left text-sm font-medium text-red-500 hover:text-red-500"
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              )
            }
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        /> */}
      </Stack>
    </Box>
  );
};

export default Navbar;
