import { useEffect } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
export const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);
  const { getItem, setItem } = useLocalStorage();

  useEffect(() => {
    const userLS = getItem("user");
    if (userLS) {
      addUser(JSON.parse(userLS));
    }
  }, []);

  const addUser = (user) => {
    setUser(user);
    setItem("user", JSON.stringify(user));
  };

  const removeUser = () => {
    setUser(null);
    setItem("user", "");
  };

  const login = (user) => {
    addUser(user);
  };

  const logout = () => {
    removeUser();
  };

  const isLoggedIn = () => {
    return !!user;
  }

  return { user, login, logout, setUser, isLoggedIn };
};