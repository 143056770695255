import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from '../../context/AuthContext'
import { format } from 'date-fns';
import Dropdown from "../dropdown/index"
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid"
import ChangeStatusListbox from "./ChangeStatusListbox";
import {
  Box,
  Button,
  FormControl,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Container,
  Paper,
} from '@mui/material';

export const Table1 = () => {
  const data = [
    {
      id: 1,
      order: "#95954",
      status: "Active",
      name: "Jone Doe",
      email: "jonne62@gmail.com",
      date: "2023-06-28",
      time: "10:00 AM",
    },
    {
      id: 2,
      order: "#95954",
      status: "Cancelled",
      name: "Jone Doe",
      email: "jonne62@gmail.com",
      date: "2023-06-29",
      time: "2:30 PM",
    },
    {
      id: 3,
      order: "#95954",
      status: "Complete",
      name: "Jone Doe",
      email: "jonne62@gmail.com",
      date: "2023-06-30",
      time: "9:15 AM",
    },
    {
      id: 4,
      order: "#95954",
      status: "Active",
      name: "Mary Poppins",
      email: "marypoppins@gmail.com",
      date: "2023-06-30",
      time: "3:45 PM",
    },
  ];

  const tableContainerStyles = {
    maxHeight: "70vh", // Set an appropriate height for the container
    overflow: "auto",
  };

  const getStatusTextColor = (status) => {
    switch (status) {
      case "Active":
        return "text-green-500";
      case "Cancelled":
        return "text-red-500";
      case "Complete":
        return "text-orange-500";
      default:
        return "";
    }
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <div className="flex justify-between items-center p-2">
              <h2 className="text-xl ">Total money spent</h2>
              <button className="px-3 py-1 text-black rounded-lg text-sm font-normal border border-gray-500">
                View Booking
              </button>
            </div>
            <div className="min-w-full">
              <div style={tableContainerStyles} className="table-container">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        ORDER
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        STATUS
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        DATE
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase"
                      >
                        CUSTOMER
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase"
                      >
                        TIME
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {data.map((item) => (
                      <tr key={item.id}>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          {item.order}
                        </td>
                        <td
                          className={`px-6 py-4 text-sm text-gray-800 whitespace-nowrap ${getStatusTextColor(
                            item.status
                          )}`}
                        >
                          {item.status}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item.date}
                        </td>
                        <td className="px-6 py-4 text-sm text-right whitespace-nowrap">
                          <a className="text-gray-800" href="#">
                            {item.name}
                          </a>
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                          <a className="text-gray-800" href="#">
                            {item.time}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Table2 = () => {
  const { user } = useContext(AuthContext);
  const [bookingData, setBookingData] = useState([]);

  const fetchBookingData = async () => {
    const url = 'https://evmainbackend.theevshowroom.com/api/bookinglistbyvendorid';
    try{
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          'Content-Type': "application/json",
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          // token: 'uoWtB46GBnIwU7vLGfVJpPnJDHFSxb0X'
          token: `${user?.token}`
        }
      });

      const responseData = await response.json();

      if(responseData && responseData.status){
        setBookingData(responseData.data);
      }
    } catch(e) {
      console.log(e);
    }
  }

  const getDate = (date) => {
    return format(new Date(date), 'MMMM do yyyy');
  }

  useEffect(() => {
    fetchBookingData();
  }, [user]);

  return (
    <>
      <div className="">
        <div className="flex justify-between  p-2">
          <h2 className="text-xl">Test Drive by models</h2>
          <button className="px-3 py-1 text-black rounded-lg text-sm font-normal border border-gray-500">
            View More
          </button>
        </div>
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-scroll border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Booking ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Model ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Model
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    User ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Email ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Address
                  </th>
                  {/* <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    State
                  </th> */}
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Zip Code
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Post
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Booking Time
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                  {
                    bookingData.map((data)=>{
                      return(
                        <tr>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.bookingId}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.modelId}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.model}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.bookingStatus}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.userid}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {`${data?.firstName} ${data?.lastName}`}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.emailId}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.address}
                          </td>
                          {/* <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.state}
                          </td> */}
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.zipcode}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.post}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {getDate(data?.created)}
                          </td>
                          <td>
                          <Dropdown
                            button={
                              <EllipsisVerticalIcon className="h-6 w-6 text-black"/>
                            } 
                            children={(openDropDown)=>{
                              return (
                                <div className="flex p-4 w-56 flex-col justify-start rounded-md bg-white text-black bg-cover bg-no-repeat border shadow-xl shadow-shadow-500 dark:shadow-none">
                                  <p className="mb-2">Change Status</p>
                                  <ChangeStatusListbox openDropDown={openDropDown} afterSubmit={fetchBookingData} status={data?.bookingStatus} bookingId={data?.bookingId} token={user?.token}/>
                                </div>
                              )
                            }
                            }
                            classNames={"py-2 top-4 -left-[135px] w-max"}
                            buttonClassName="ml-auto"
                          />
                          </td>
                        </tr>
                      )
                    })
                  }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export const Table3 = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);

  const fetchProductClickData = async () => {
    const url = 'https://evmainbackend.theevshowroom.com/api/vendorclickedproductlist';
    try{
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          'Content-Type': "application/json",
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          token: `${user?.token}`
        }
      });

      const responseData = await response.json();

      if(responseData && responseData.status){
        setData(responseData.data?.clickModelData);
      }
      else{
        setData([]);
      }
    } catch(e) {
      console.log(e);
    }
  }

  useEffect(() => {
   fetchProductClickData();
  }, [user]);

  const tableContainerStyles = {
    maxHeight: "70vh", // Set an appropriate height for the container
    overflow: "auto",
  };

  const getDate = (date) => {
    return format(new Date(date), 'MMMM do yyyy');
  }

  const getTime = (date) => {
    return format(new Date(date), 'h:mm:ss a');
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <div className="flex justify-between items-center p-2">
              <h2 className="text-xl ">Product Click Details</h2>
              <button className="px-3 py-1 text-black rounded-lg text-sm font-normal border border-gray-500">
                View Booking
              </button>
            </div>
            <div className="min-w-full">
              <div style={tableContainerStyles} className="table-container">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        NAME
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        DATE
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase"
                      >
                        TIME
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {data.map((item) => (
                      <tr key={item.id}>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          {item.modelid}
                        </td>
                        <td
                          className={`px-6 py-4 text-sm text-gray-800 whitespace-nowrap`}
                        >
                          {item.model}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {getDate(item.created)}
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                          <a className="text-gray-800" href="#">
                            {getTime(item.created)}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Table4 = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);

  const fetchProductClickData = async () => {
    const url = 'https://evmainbackend.theevshowroom.com/api/vendorclickedproductlist';
    try{
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          'Content-Type': "application/json",
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          token: `${user?.token}`
        }
      });

      const responseData = await response.json();

      if(responseData && responseData.status){
        setData(responseData.data?.clickToVendorData);
      }
      else{
        setData([]);
      }
    } catch(e) {
      console.log(e);
    }
  }

  useEffect(() => {
   fetchProductClickData();
  }, [user]);

  const tableContainerStyles = {
    maxHeight: "70vh", // Set an appropriate height for the container
    overflow: "auto",
  };

  const getDate = (date) => {
    return format(new Date(date), 'MMMM do yyyy');
  }

  const getTime = (date) => {
    return format(new Date(date), 'h:mm:ss a');
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <div className="flex justify-between items-center p-2">
              <h2 className="text-xl ">Brand Click Details</h2>
              <button className="px-3 py-1 text-black rounded-lg text-sm font-normal border border-gray-500">
                View Booking
              </button>
            </div>
            <div className="min-w-full">
              <div style={tableContainerStyles} className="table-container">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        NAME
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        DATE
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase"
                      >
                        TIME
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {data.map((item) => (
                      <tr key={item.id}>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          {item.modelid}
                        </td>
                        <td
                          className={`px-6 py-4 text-sm text-gray-800 whitespace-nowrap`}
                        >
                          {item.model}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {getDate(item.created)}
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                          <a className="text-gray-800" href="#">
                            {getTime(item.created)}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TableTransactions = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const fetchVendorTransactions = async () => {
    const url = `https://evmainbackend.theevshowroom.com/api/getTransactionsByVendorId?vendorId=${user?.id}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          'Content-Type': "application/json",
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          token: `${user?.token}`
          // token: `WgRaURSrJWxaUWRGEKzBVWybNuOiMcAz`
        }
      });

      const responseData = await response.json();

      if (responseData && responseData.status) {
        setData(responseData.data);
      } else {
        setData([]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if(user){
      fetchVendorTransactions();
    }
  }, [user]);

  const formatDate = (date) => {
    return format(new Date(date), 'MMMM do yyyy');
  }

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    setStartDate('');
    setEndDate('');
    setAnchorEl(null);
  }

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setSelectedMonth('');
  }

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setSelectedMonth('');
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const filteredData = data.filter(item => {
    if (selectedMonth) {
      return new Date(item.transaction_date).getMonth() === new Date(`${selectedMonth} 1, 2024`).getMonth();
    } else if (startDate && endDate) {
      const transactionDate = new Date(item.transaction_date);
      return transactionDate >= new Date(startDate) && transactionDate <= new Date(endDate);
    } else {
      return true;
    }
  });

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  return (
    <Container maxWidth={false}>
      <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
        <Typography variant="h5">Transaction Details</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControl variant="outlined" size="small">
            <Button
              variant="outlined"
              onClick={handleMenuClick}
              aria-controls="month-menu"
              aria-haspopup="true"
              sx={{
                borderColor: 'black',
                color: 'black',
                '&:hover': {
                  borderColor: 'black',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                }
              }}
            >
              {selectedMonth || "Filter by Month"}
            </Button>
            <Menu
              id="month-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMonthChange('')}>
                <em>All</em>
              </MenuItem>
              {months.map((month) => (
                <MenuItem key={month} onClick={() => handleMonthChange(month)}>
                  {month}
                </MenuItem>
              ))}
            </Menu>
          </FormControl>
          <TextField
            type="date"
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={handleStartDateChange}
            variant="outlined"
            size="small"
          />
          <TextField
            type="date"
            label="End Date"
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={handleEndDateChange}
            variant="outlined"
            size="small"
          />
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Transaction Date</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Vendor Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.transaction_id}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell>{formatDate(item.transaction_date)}</TableCell>
                <TableCell>{item.created_by}</TableCell>
                <TableCell className="capitalize">{item.vendor_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export const ClickTransactionsTable = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const fetchVendorClickTransactions = async () => {
    const url = `https://evmainbackend.theevshowroom.com/api/getClickTransactionsByVendorId?vendorId=${user?.id}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          'Content-Type': "application/json",
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          token: `${user?.token}`
          // token: `WgRaURSrJWxaUWRGEKzBVWybNuOiMcAz`
        }
      });

      const responseData = await response.json();

      if (responseData && responseData.status) {
        setData(responseData.data);
      } else {
        setData([]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if(user){
      fetchVendorClickTransactions();
    }
  }, [user]);

  const formatDate = (date) => {
    return format(new Date(date), 'MMMM do yyyy');
  }

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    setStartDate('');
    setEndDate('');
    setAnchorEl(null);
  }

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setSelectedMonth('');
  }

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setSelectedMonth('');
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const filteredData = data.filter(item => {
    if (selectedMonth) {
      return new Date(item.created).getMonth() === new Date(`${selectedMonth} 1, 2024`).getMonth();
    } else if (startDate && endDate) {
      const transactionDate = new Date(item.created);
      return transactionDate >= new Date(startDate) && transactionDate <= new Date(endDate);
    } else {
      return true;
    }
  });

  const totalClicks = filteredData.length;
  const totalMoneySpent = filteredData.reduce((total, item) => total + parseFloat(item.amount), 0);

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  return (
    <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
        <Typography variant="h5">Click Transaction Details</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControl variant="outlined" size="small">
            <Button
              variant="outlined"
              onClick={handleMenuClick}
              aria-controls="month-menu"
              aria-haspopup="true"
              sx={{
                borderColor: 'black',
                color: 'black',
                '&:hover': {
                  borderColor: 'black',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                }
              }}
            >
              {selectedMonth || "Filter by Month"}
            </Button>
            <Menu
              id="month-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMonthChange('')}>
                <em>All</em>
              </MenuItem>
              {months.map((month) => (
                <MenuItem key={month} onClick={() => handleMonthChange(month)}>
                  {month}
                </MenuItem>
              ))}
            </Menu>
          </FormControl>
          <TextField
            type="date"
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={handleStartDateChange}
            variant="outlined"
            size="small"
          />
          <TextField
            type="date"
            label="End Date"
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={handleEndDateChange}
            variant="outlined"
            size="small"
          />
        </Box>
      </Box>
      <TableContainer sx={{ flexGrow: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Amount (€)</TableCell>
              <TableCell>Transaction Date</TableCell>
              <TableCell>Vendor Name</TableCell>
              <TableCell>Model Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>€ {item.amount}</TableCell>
                <TableCell>{formatDate(item.created)}</TableCell>
                <TableCell>{item.vendor_name}</TableCell>
                <TableCell>{item.model_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ bgcolor: 'white', p: 2, boxShadow: 1, borderTop: 1, borderColor: 'grey.300', position: 'sticky', bottom: 8, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" fontWeight="bold">Total Clicks: {totalClicks}</Typography>
        <Typography variant="body1" fontWeight="bold">Total Money Spent: € {totalMoneySpent.toFixed(2)}</Typography>
      </Box>
    </Container>
  );
};

export const ClicksOverTimeTable = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth()+1);
  const [isLoading, setIsLoading] = useState(false);

  const fetchClicksOverTime = async () => {
    setIsLoading(true);
    const url = `https://evmainbackend.theevshowroom.com/api/clicksOverTime?year=${year}&month=${month}&vendorId=${user?.id}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          'Content-Type': "application/json",
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          token: `${user?.token}`
        }
      });

      const responseData = await response.json();

      if (responseData && responseData.success) {
        setData(responseData.data);
      } else {
        setData([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (user) {
      fetchClicksOverTime();
    }
  }, [user, year, month]);

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const months = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
  ];

  return (
    <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
        <Typography variant="h5">Clicks From IP Addresses</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            select
            label="Year"
            value={year}
            onChange={handleYearChange}
            variant="outlined"
            size="small"
            sx={{ minWidth: 100 }}
          >
            {[2024, 2023, 2022].map((year) => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Month"
            value={month}
            onChange={handleMonthChange}
            variant="outlined"
            size="small"
            sx={{ minWidth: 120 }}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>{month.label}</MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <TableContainer sx={{ flexGrow: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Clicks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((item) => (
                <TableRow key={item.date}>
                  <TableCell>{format(new Date(item.date), 'MMMM do yyyy')}</TableCell>
                  <TableCell>{item.clicks}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No data available for the selected period.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export const UserInteractionPatternsTable = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth()+1);
  const [loading, setLoading] = useState(false);

  const fetchUserInteractionPatterns = async () => {
    const url = `https://evmainbackend.theevshowroom.com/api/userInteractionPatterns?year=${year}&month=${month}&vendorId=${user?.id}`;
    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          'Content-Type': "application/json",
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          token: `${user?.token}`
        }
      });

      const responseData = await response.json();

      if (responseData && responseData.success) {
        setData(responseData.data);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user) {
      fetchUserInteractionPatterns();
    }
  }, [user, year, month]);

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const months = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
  ];

  return (
    <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
        <Typography variant="h5">User Interaction Patterns</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            select
            label="Year"
            value={year}
            onChange={handleYearChange}
            variant="outlined"
            size="small"
            sx={{ minWidth: 100 }}
          >
            {[2024, 2023, 2022].map((year) => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Month"
            value={month}
            onChange={handleMonthChange}
            variant="outlined"
            size="small"
            sx={{ minWidth: 120 }}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>{month.label}</MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <TableContainer sx={{ flexGrow: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>IP Address</TableCell>
                <TableCell>Model Name</TableCell>
                <TableCell>Clicks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No data available for the selected period.
                </TableCell>
              </TableRow>
            ) : (
                data.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.interactions.map((interaction) => (
                      <TableRow key={interaction.modelId}>
                        <TableCell>{item.ipAddress}</TableCell>
                        <TableCell>{interaction.modelName}</TableCell>
                        <TableCell>{interaction.clicks}</TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};