import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const MonthlySpendingCard = ({ data }) => {
  // State to manage selected month
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedData, setSelectedData] = useState(null);

  const theme = useTheme();
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'));

  // Update selected month and data when new data comes in
  useEffect(() => {
    if (data.length > 0) {
      setSelectedMonth(data[data.length - 1].month);
      setSelectedData(data[0]);
    }
  }, [data]);

  const handleChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    const newSelectedData = data.find((item) => item.month === month);
    setSelectedData(newSelectedData);
  };

  return (
    <Card>
      <CardContent>
        <div
          style={{
            display: 'flex',
            flexDirection: isXlUp ? 'column' : 'row',
            justifyContent: isXlUp ? 'center' : 'space-between',
            alignItems: isXlUp ? '' : 'baseline',
          }}
        >
          <Typography variant="h6" component="div" style={{ marginBottom: isXlUp ? '16px' : '0' }}>
            Monthly Spending
          </Typography>
          <FormControl variant="outlined" size="small">
            <InputLabel id="month-selector-label">Month</InputLabel>
            <Select
              labelId="month-selector-label"
              value={selectedMonth}
              onChange={handleChange}
              label="Month"
              disabled={data.length === 0} // Disable the selector if there's no data
            >
              {data.map((item) => (
                <MenuItem key={item.month} value={item.month}>
                  {item.displayText}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {selectedData ? (
          <Typography variant="h4" component="div" style={{ marginTop: '16px' }}>
            €{selectedData.total_amount}
          </Typography>
        ) : (
          <Typography variant="h6" component="div" style={{ marginTop: '16px' }}>
            Loading...
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default MonthlySpendingCard;
