import React from "react";

import Dashboard from "./views/admin/dashboard";

import Products from "./views/admin/products";
import BrandClick from "./views/admin/brandClick";
import TestDrive from "./views/admin/testdrive";
import Payment from "./views/admin/payment";

import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EuroIcon from '@mui/icons-material/Euro';
import Transactions from "./views/admin/transactions";
import ClickVendorTransactions from "./views/admin/click-payments";
import ClickDashboardTime from "./views/admin/ipClick";
import ClickDashboardModels from "./views/admin/ipModelClick";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <HomeIcon color="inherit" className="h-6 w-6" />,
    component: <Dashboard />,
  },

  {
    name: "Model Click",
    layout: "/admin",
    path: "model-click",
    icon: <ShoppingCartIcon className="h-6 w-6" />,
    component: <Products />,
    secondary: true,
  },

  {
    name: "Brand Click",
    layout: "/admin",
    path: "brand-click",
    icon: <ShoppingCartIcon className="h-6 w-6" />,
    component: <BrandClick />,
    secondary: true,
  },
  {
    name: "IPAddress Clicks",
    layout: "/admin",
    path: "ip-clicks",
    icon: <DirectionsCarIcon className="h-6 w-6" />,
    component: <ClickDashboardTime />,
    secondary: true,
  },
  {
    name: "IPAddress Model Clicks",
    layout: "/admin",
    path: "ip-model-clicks",
    icon: <DirectionsCarIcon className="h-6 w-6" />,
    component: <ClickDashboardModels />,
    secondary: true,
  },
  {
    name: "Test drives Booking",
    layout: "/admin",
    path: "test-drives",
    icon: <DirectionsCarIcon className="h-6 w-6" />,
    component: <TestDrive />,
    secondary: true,
  },
  {
    name: "Payments",
    layout: "/admin",
    path: "payments",
    icon: <EuroIcon className="h-6 w-6" />,
    component: <Payment />,
    secondary: true,
  },
  {
    name: "Transactions",
    layout: "/admin",
    path: "transactions",
    icon: <EuroIcon className="h-6 w-6" />,
    component: <Transactions />,
    secondary: true,
  },
  {
    name: "Click Payments",
    layout: "/admin",
    path: "click-payments",
    icon: <EuroIcon className="h-6 w-6" />,
    component: <ClickVendorTransactions />,
    secondary: true,
  },
];
export default routes;
