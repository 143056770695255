import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate } from "react-router-dom";

const getStatusColor = (status) => {
  switch (status) {
    case 'booked':
      return 'blue';
    case 'active':
      return 'green';
    case 'rejected':
      return 'red';
    default:
      return 'black';
  }
};

const TodaysTestDrivesCard = ({ data }) => {
  const [testDrivesData, setTestDrivesData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (data.length > 0) {
      setTestDrivesData(data.slice(0, 5)); // Show only the first 5 rows
    }
  }, [data]);

  const handleViewAllClick = () => {
    navigate('/admin/brand-click');
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          Today's Test Drives
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Model</TableCell>
                <TableCell align="right">Booking Status</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {testDrivesData.length > 0 ? (
                testDrivesData.map((row) => (
                  <TableRow key={row.bookingId}>
                    <TableCell>{row.model}</TableCell>
                    <TableCell align="right" style={{ color: getStatusColor(row.bookingStatus) }}>
                      {row.bookingStatus}
                    </TableCell>
                    <TableCell align="right">{new Date(row.created).toLocaleDateString()}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleViewAllClick}
          style={{ marginTop: '16px' }}
        >
          View All
        </Button>
      </CardContent>
    </Card>
  );
};

export default TodaysTestDrivesCard;
