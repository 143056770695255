import React from 'react'
import { Table4 } from '../../../components/dashboard/Table'

const BrandClick = () => {
  return (
    <div className='sm:px-8'>
      <Table4/>
    </div>
  )
}

export default BrandClick
