import React from 'react'
import { ClickTransactionsTable } from '../../../components/dashboard/Table'

const ClickVendorTransactions = () => {
  return (
    <div className='sm:px-8'>
      <ClickTransactionsTable/>
    </div>
  )
}

export default ClickVendorTransactions
