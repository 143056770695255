import React from 'react'
import { Table3 } from '../../../components/dashboard/Table'

const Products = () => {
  return (
    <div className='sm:px-8'>
      <Table3/>
    </div>
  )
}

export default Products
