import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate } from "react-router-dom";

const TodaysClicksCard = ({ data }) => {
  const [clickData, setClickData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(data)
    if (data.length > 0) {
      setClickData(data.slice(0, 5)); // Show only the first 5 rows
    }
  }, [data]);

  const handleViewAllClick = () => {
    navigate('/admin/brand-click');
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          Today's Clicks
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Model Name</TableCell>
                <TableCell align="right">Click Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clickData.length > 0 ? (
                clickData.map((row) => (
                  <TableRow key={row.model_id}>
                    <TableCell>{row.model_name}</TableCell>
                    <TableCell align="right">{row.click_count}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleViewAllClick}
          style={{ marginTop: '16px' }}
        >
          View All
        </Button>
      </CardContent>
    </Card>
  );
};

export default TodaysClicksCard;
