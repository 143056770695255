import { Container, Grid } from "@mui/material";
import TotalAmountLeftCard from "../../../components/dashboard/TotalAmountLeftCard";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from '../../../context/AuthContext'
import MonthlySpendingCard from "../../../components/dashboard/MonthlySpendingCard";
import TodaysClicksCard from "../../../components/dashboard/TodaysClicksData";
import TodaysTestDrivesCard from "../../../components/dashboard/TodaysTestDrivesCard";
import DailyClicksChart from "../../../components/dashboard/DailyClicksChart";

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState({
    remainingBalance: "",
    monthlySpends: [],
    todayNoOfClicks: [],
    todaysTestDrives: [],
    dayWiseClick: []
  });

  const fetchVendorClickTransactions = async () => {
    const url = `https://evmainbackend.theevshowroom.com/api/VendorDashboard?vendorId=${user?.id}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          'Content-Type': "application/json",
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          token: `${user?.token}`
        }
      });
  
      const responseData = await response.json();
  
      if (responseData && responseData.status) {
        console.log(responseData)
        setData({
          remainingBalance: responseData?.data?.balanceLeft[0]?.balance || "",
          monthlySpends: responseData?.data?.monthlySpends || [],
          todayNoOfClicks: responseData?.data?.todayNoOfClicks || [],
          todaysTestDrives: responseData?.data?.todaysTestDrives || [],
          dayWiseClick: responseData?.data?.dayWiseClick || []
        });
      } else {
        setData([]);
      }
    } catch (e) {
      console.log(e);
    }
  }
  
  useEffect(() => {
    console.log("user: ",user)
    if(user){
      fetchVendorClickTransactions();
    }
  }, [user]);

  return (
    <Container maxWidth="2xl">
      <Grid container spacing={3}>
        <Grid item md={12} lg={8}>
          <DailyClicksChart data={data?.dayWiseClick} />
        </Grid>
        <Grid item md={12} lg={4}>
          <Grid container spacing={5}>
            <Grid item md={12}>
              <TotalAmountLeftCard text="Total amount left" totalAmountLeft={data?.remainingBalance} />
            </Grid>
            <Grid item md={12}>
              <MonthlySpendingCard data={data?.monthlySpends} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} lg={6}>
          <TodaysClicksCard data={data?.todayNoOfClicks}/>
        </Grid>
        <Grid item md={12} lg={6}>
          <TodaysTestDrivesCard data={data?.todaysTestDrives}/>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
